import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path:'',
    loadChildren : () => import('./modules/home/home.module').then((m)=> m.HomeModule)
  },
  {
    path:'pricing',
    loadChildren : () => import('./modules/pricing/pricing.module').then((m) => m.PricingModule)
  },
  {
    path:'contact-us',
    loadChildren : () => import('./modules/contact-us/contact-us.module').then((m) => m.ContactUsModule)
  },
  {
    path:'terms-conditions',
    loadChildren : () => import('./modules/terms/terms.module').then((m) => m.TermsModule)
  },
  {
    path:'privacy-policy',
    loadChildren : () => import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule)
  },
  {
    path:'refunds',
    loadChildren : () => import('./modules/refunds/refund.module').then((m) => m.RefundModule)
  },
  {
    path:'cancellation',
    loadChildren : () => import('./modules/cancellation/cancellation.module').then((m) => m.CancellationModule)
  },
  {
    path:'blogs',
    loadChildren : () => import('./modules/blogs/blogs.module').then((m) => m.BlogsModule)
  },
  {
    path: 'affiliate-program',
    loadChildren : () => import('./modules/affiliate-program/affiliate-program.module').then((m) => m.AffiliateProgramModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
