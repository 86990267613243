import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CanonicalService } from './services/canonical.service';
import { TemplateService } from './services/template.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(
    private router : Router,
    private activate : ActivatedRoute,
    private canonicalService : CanonicalService,
    private service : TemplateService
  ){}

  termsPage : boolean = false;
  privacyPage : boolean = false;
  currentPageUrl : any;
  ngOnInit(){
    this.router.events.subscribe((ev) => {
      if(ev instanceof NavigationEnd){
        // console.log(ev)
        this.currentPageUrl = ev.url;
        if(this.currentPageUrl?.includes('terms-conditions')){          
          return;
        }
        if(this.currentPageUrl?.includes('privacy-policy')){
          return;
        }
      }
    })

    this.activate.queryParams.subscribe((qParam)=>{
      const partnerId = qParam["partnerId"]
      console.log(partnerId);
      if(partnerId)
      {
        localStorage.setItem("partnerId",partnerId);
        console.log("This is saved in local storage",localStorage.getItem("partnerId"));
        this.service.captureUser(localStorage.getItem("partnerId")).subscribe((res)=>{
          console.log(res);
        })
      }
    })


    // this.canonicalService.updateCanonical();
  }
}
